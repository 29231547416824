<template>
  <div class="page-wrapper">
    <b-container fluid>
      <bo-page-title>
      </bo-page-title>
      <b-row>
        <b-col lg="4">
          <b-card no-body>
            <b-card-header>
              <b-row>
                <b-col lg="8">
                  <h5 class="card-title">Form</h5>
                </b-col>
              </b-row>
            </b-card-header>
            <b-card-body>
              <b-row>
                <b-col lg="12">
                  <b-form-group label-for="articleTitle">
                    <template #label>Title<span class="text-danger mr5">*</span></template>
                    <b-form-input id="articleTitle" value="Berlangganan Duwitmu" v-model="articleRow.title"
                      placeholder="e.g Berlangganan Duwitmu" />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card-body>
            <template #footer>
              <div class="text-right">
                <b-button class="btn-rounded" variant="default" @click="cancelAddUpdate">Cancel
                </b-button>
                <b-button class="btn-rounded" variant="success" type="submit">Update</b-button>
              </div>
            </template>
          </b-card>
        </b-col>
        <b-col lg="8">
          <b-card no-body>
            <b-card-header>
              <b-row>
                <b-col lg="8">
                  <h5 class="card-title">Subscriber List</h5>
                </b-col>
              </b-row>
            </b-card-header>
            <b-card-body>
               <b-row class="mb_20">
              <b-col md="auto">
                <b-button-group>
                  <b-button v-b-tooltip.hover title="Delete"><i class="ti-export mr-2"></i> Export</b-button>
                </b-button-group>
              </b-col>
               </b-row>
              <div class="table-responsive">
                <table class="table table-hover table-striped table-bordered">
                  <thead>
                    <tr>
                      <th scope="col">
                        #
                      </th>
                      <th scope="col">Name</th>
                      <th scope="col">Email</th>
                      <th scope="col">Subscribe Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th>
                        1
                      </th>
                      <td>
                        <div class="wrap_title_post">
                          <h3>AaccitteTD</h3>
                        </div>
                      </td>
                      <td>mrko00d3345.1@gmail.com</td>
                      <td>
                        2021/11/08 at 9:00 am
                      </td>
                    </tr>
                    <tr>
                      <th>
                        2
                      </th>
                      <td>
                        <div class="wrap_title_post">
                          <h3>AbbeyBernhardt</h3>
                        </div>
                      </td>
                      <td>oakleyshops.ervice@gmail.com</td>
                      <td>
                        2021/11/08 at 9:00 am
                      </td>
                    </tr>
                     <tr>
                      <th>
                        3
                      </th>
                      <td>
                        <div class="wrap_title_post">
                          <h3>AbbeyBernhardt</h3>
                        </div>
                      </td>
                      <td>oakleyshops.ervice@gmail.com</td>
                      <td>
                        2021/11/08 at 9:00 am
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </b-card-body>
             <template #footer>
              <b-pagination class="mb-0" v-model="currentPage" :total-rows="rows" :per-page="perPage" prev-text="Prev"
                next-text="Next" />
            </template>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
  import GlobalVue from '@/libs/Global.vue'
  export default {
    name: 'Article',
    extends: GlobalVue,
    components: {},
    data() {
      return {
        selected: 'A',
        ads_device: [{
            item: 'A',
            name: 'Desktop'
          },
          {
            item: 'B',
            name: 'Mobile'
          }
        ],
        date: new Date(),
        days: [],
        featured: 'not_accepted',
        seoRow: {},
        articleRow: {},
        articleData: [],
        rows: 30,
        perPage: 1,
        currentPage: 5,
        selected_category: 'All Category',
        category: [
          'All Category',
          'Pinjaman Online',
          'Asuransi',
          'Bank Digital',
          'Investasi',
          'Rencana Keuangan',
        ],
        selected_sort: 'Sort By',
        sort: [
          'Sort By',
          'Most Viewed',
          'Least Viewed',
          'Oldest',
          'Newest',
        ],
        selected_ads_size: 'Select Ads Size',
        ads_size: [
          'Select Ads Size',
          '300x250',
          '336x280',
          '728x90',
          '300x600',
          '320x100',
          '200x50',
          '320x20',
        ],
        selected_parent: ' ---Select Parent Catgeory ---',
        parent: [
          ' ---Select Parent Catgeory ---',
          'Asuransi',
          'Advetorial',
          'Pengeluaran',
          'Saham'
        ],
        selected_comment: 'Y',
        comment: [{
            item: 'Y',
            name: 'Yes'
          },
          {
            item: 'N',
            name: 'No'
          }
        ],
      }
    }
  }
</script>